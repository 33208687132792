<template>
  <div>
    <PageHeader title="ตั้งค่าเอเย่นต์" />
    <b-overlay :show="isLoading">
      <b-card>
      <div v-if="isSettingData" class="text-center">
        <b-spinner size="lg" />
      </div>
      <ValidationObserver
          v-else
          ref="agentForm"
          v-slot="{ handleSubmit, failed }"
      >
        <b-toast
            :visible="failed"
            title="Warning"
            variant="warning"
        >
          {{ $t('messages.invalid_form') }}
        </b-toast>
        <form
            autocomplete="off"
            @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-tabs
            pills
            vertical
          >
            <b-tab
                v-if="$allowPermission('agent:theme.setting')"
                title="ตั้งค่าเว็บไซต์"
                active
            >
              <AgentWebsiteSetting
                  :agent-data="agentData"
                  :is-update="true"
                  @update="updateAgentData"
              />
            </b-tab>
            <b-tab
                v-if="$allowPermission('agent:line-login.setting')"
                title="ตั้งค่าล็อคอินผ่านไลน์"
            >
              <AgentLineLoginSetting
                  :agent-data="agentData"
                  @update="updateAgentData"
              />
            </b-tab>

            <b-tab
                v-if="$allowPermission('agent:contact.setting')"
                :title="$t('contact.title')"
            >
              <AgentContactSetting
                  :agent-data="agentData"
                  @update="updateAgentData"
              />
            </b-tab>

            <b-tab
                v-if="$allowPermission('agent:withdraw.setting')"
                title="ตั้งค่าการถอนเงิน"
            >
              <AgentWithdrawSetting
                  :agent-data="agentData"
                  @update="updateAgentData"
              />
            </b-tab>

            <b-tab
                v-if="userInfo.allowPromotionTypeList.includes('11') && $allowPermission('agent:cashback.setting')"
                title="ตั้งค่าระบบเงินคืน"
            >
              <AgentCashbackSetting
                  :agent-data="agentData"
                  @update="updateAgentData"
              />
            </b-tab>

            <b-tab
                v-if="userInfo.allowPromotionTypeList.includes('9') && $allowPermission('agent:af.setting')"
                title="ตั้งค่าระบบแนะนำ"
            >
              <AgentAFSetting
                  :agent-data="agentData"
                  @update="updateAgentData"
              />
            </b-tab>

            <b-tab
                v-if="$allowPermission('agent:skip-verification.setting')"
                title="ตั้งค่าสมัครสมาชิก"
            >
              <AgentSettingRegister
                  :agent-data="agentData"
                  @update="updateAgentData"
              />
            </b-tab>
          </b-tabs>

          <div class="text-right">
            <b-button
                type="submit"
                variant="primary"
            >
              {{ $t('buttons.save') }}
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  components: {
    AgentWebsiteSetting: () => import('@components/agent-setting/website'),
    AgentContactSetting: () => import('@components/agent-setting/contact'),
    AgentLineLoginSetting: () => import('@components/agent-setting/line-login'),
    AgentWithdrawSetting: () => import('@components/agent-setting/withdraw'),
    AgentCashbackSetting: () => import('@components/agent-setting/cashback'),
    AgentAFSetting: () => import('@components/agent-setting/affiliate'),
    AgentSettingRegister: () => import('@components/agent-setting/register')
  },
  page: {
    title: 'Agent Settings',
  },
  data() {
    return {
      isLoading: false,
      isSettingData: true,
      agentData: {},
      gamesData: [],
      levelData: {
        level: 1,
        commissionRate: '',
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'agentDetail']),
    agentId() {
      return this.userInfo.agentId
    },
    isUpdatingDepositSettings() {
      return false
    },
    settingType() {
      return this.$route.query['setting_type']
    },
  },
  watch: {
    async agentId(val) {
      if(val) {
        await this.fetchAgent(val)
        await this.setData()
      }
    }
  },
  methods: {
    ...mapActions(['fetchAgent', 'updateAgent']),
    updateAgentData(value) {
      this.agentData = {
        ...this.agentData,
        ...value
      }
    },
    setData() {
      this.isSettingData = true
        // parse all values
      const data = JSON.parse(JSON.stringify(this.agentDetail))

      this.agentData = {
        ...data,
        metaKeyword: data.metaKeyword ? data.metaKeyword.split(',') : [],
        cashbackTime: {
          sunday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          monday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          tuesday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          wednesday: {
            isEnable: false,
            startTime: '00:00',
            endTime: '24:00',
          },
          thursday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          friday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          saturday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          ...data.cashbackTime,
        },
        affiliateLevelSettings: this.formatAffiliateData(data.affiliateLevelSettings)
      }

      // has game config
      if (data.agentGameConfigs && data.agentGameConfigs.length > 0) {
        this.setGameData(data.agentGameConfigs)
      }
      this.isSettingData = false
    },
    formatAffiliateData(settings) {
      const affiliateLevelSettings = []
      settings.forEach((affiliateLevelSetting) => {
        let { level, gameType } = affiliateLevelSetting
        level = level - 1
        if(!affiliateLevelSettings[level]) {
          affiliateLevelSettings[level] = { commissionRate: {} }
        }
        if(!affiliateLevelSettings[level].commissionRate[gameType]) {
          affiliateLevelSettings[level].commissionRate[gameType] = affiliateLevelSetting.commissionRate
        }
      })
      return affiliateLevelSettings
    },
    setGameData(gameConfigs) {
      if (this.games && this.games.length > 0) {
        this.gamesData = []
        let gameData = {}

        this.games.forEach((game) => {
          let found = false
          if(gameConfigs && gameConfigs.length > 0) {
            found = gameConfigs.find(
                (config) => config.gameConfigId === game.id
            )
          }

          if (found) {
            const providerKey = found.providerKey ? JSON.parse(found.providerKey) : {}
            const commissionRate = found && found.commissionRate ? found.commissionRate : game.commissionRate
            const hasGameKey = Object.keys(providerKey).length > 0
            providerKey['hasGameKey'] = hasGameKey
            gameData = {
              ...game,
              gameConfigId: game.id,
              isSelect: !!found,
              providerKey: {
                ...providerKey,
                hasGameKey
              },
              commissionRate: commissionRate,
              masterCommissionRate: game.commissionRate,
            }
          } else {
            const providerKey = game.providerKey ? JSON.parse(game.providerKey) : {}
            gameData = {
              ...game,
              providerKey: {
                ...providerKey,
                hasGameKey: false
              },
              gameConfigId: game.id,
              isSelect: false,
            }
          }

          // has bet limit config
          if (gameData.providerKey['GAME_BET_LIMIT']) {
            const betLimitConfig =
                gameData.providerKey['GAME_BET_LIMIT'].length
                    ? JSON.parse(gameData.providerKey['GAME_BET_LIMIT'])
                    : []
            gameData.providerKey['GAME_BET_LIMIT'] = this.mapValueToBetOption(
                betLimitConfig,
                gameData.rawName
            )
          }

          this.gamesData.push(gameData)
        })
      }
    },
    async onSubmit() {
      this.isLoading = true
      if (this.gamesData.length > 0) {
        const agentGameConfigs = this.gamesData.filter(
            (game) => game.isSelect === true
        )
        this.agentData.agentGameConfigs = agentGameConfigs.map((game) => {
          if (Array.isArray(game.providerKey['GAME_BET_LIMIT'])) {
            game.providerKey['GAME_BET_LIMIT'] = JSON.stringify(
                game.providerKey['GAME_BET_LIMIT'].map((bl) => bl.value)
            )
          }
          let providerKey = ''
          if(game.providerKey.hasGameKey && Object.keys(game.providerKey).length) {
            providerKey = { ...game.providerKey }
            delete providerKey.hasGameKey
            providerKey = JSON.stringify(providerKey)
          }
          return {
            gameConfigId: game.gameConfigId,
            commissionRate: game.commissionRate,
            providerKey: providerKey,
          }
        })
      }
      this.agentData.minWithdrawCredit = +this.agentData.minWithdrawCredit
      this.agentData.maxWithdrawCreditTimePerDay =
          +this.agentData.maxWithdrawCreditTimePerDay
      this.agentData.minWithdrawAffiliate = +this.agentData.minWithdrawAffiliate
      this.agentData.maxWithdrawAffiliateTimePerDay =
          +this.agentData.maxWithdrawAffiliateTimePerDay
      this.agentData.cashbackCommissionRate =
          +this.agentData.cashbackCommissionRate
      this.agentData.cashbackQuotaPerDay = +this.agentData.cashbackQuotaPerDay
      this.agentData.minWithdrawCashback = +this.agentData.minWithdrawCashback
      this.agentData.maxWithdrawCashbackTimePerDay =
          +this.agentData.maxWithdrawCashbackTimePerDay
      this.agentData.credit = +this.agentData.credit

      const affiliateLevelSettings = this.agentData.affiliateLevelSettings.map((affiliateLevelSetting, index) => {
        return {
          level: index + 1,
          commissionRate: affiliateLevelSetting.commissionRate
        }
      })
      this.updateAgent({
        id: this.agentId,
        data: {
          ...this.agentData,
          affiliateLevelSettings,
          metaKeyword: this.agentData.metaKeyword.join()
        }
      })
      this.isLoading = false
    },
  },
}
</script>

<style>
.tab-content {
  padding-top: 0;
}
</style>